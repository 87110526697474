@import "../../index.scss";

.wBox {
  height: 140px;
  .box-wrapper {
    display: flex;
    box-shadow: 0 0 3px rgb(211, 211, 211);
    border-radius: 0.3rem;
    img {
        object-fit: cover;
      height: 120px;
      width: 140px;
    }
  }
  .box-content {
    display: flex;
    flex-direction: column;
    h2 {
      color: black;
      padding: 0 0.3rem;
    }

    .box-content-3 {
      color: rgb(13, 78, 13);
      margin-top: auto;
      padding: 0 0.3rem;
    }
  }
}
@media screen and (min-width: 768px) {
  .wBox {
    width: 100%;
    .box-wrapper {
      // border: 2px solid red;
      flex-direction: column;
      width: 100%;
      height: 350px;
      box-shadow: 0 0 5px rgb(111, 111, 111);
      img {
        object-fit: cover;
        height: 210px;
        width: 100%;
      }
      .box-content {
        display: flex;
        flex-direction: column;
        //   border: 1px solid green;
        height: 130px;
        h2 {
          color: black;
          font-size: 1.28rem;
          line-height: 1.5rem;
          padding: 0 0.4rem;
          margin: 0;
        }
        .box-content-3 {
          font-size: 1.1rem;
          color: black;
          color: rgb(13, 78, 13);
          margin: 0;
          margin-top: auto;
          padding: 0 0.4rem;
          // margin: 0;
          // border: 1px solid red;
        }
      }
    }
  }
}
